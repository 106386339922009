import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-three'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ServicesArea from '../containers/it-services/services-area'
import ContactInfoArea from '../containers/global/contact-area/contact-two'
import SectionSix from '../containers/elements/tabs/section-six'

const CloudPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Cloud" />
    <Header transparent/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Cloud"
    />
    <main className="site-wrapper-reveal">
        <SectionSix/>
        {/* <ContactInfoArea/> */}
    </main>
    <Footer/>
  </Layout>
)
 
export default CloudPage