import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from '../../../../components/ui/tabs/layout-one'
import { SectionWrap } from './style'
import CloudImg from '../../../../data/images/box-image/case-study-04-featured.jpg'
import { MdDone } from "react-icons/md";

const TabsSection = () => {
    return (
        <SectionWrap>
                <Container>
                    <Row  className="rowspace">
                    <Col lg={5}>
                            <img src={"../../../../cloud.png"} alt="cloud app"></img>
                        </Col>
                        <Col lg={7} >
                            <p>Rapidly modernize applications, migrate the right workloads, and securely manage
                            your multi-cloud environment.
                            Whether you're a multinational corporation implementing virtual desktops for
                            employees, or a SaaS provider migrating from an all-public to a hybrid cloud
                            infrastructure, Precursor will assist you in your digital transformation journey.</p>

                        </Col>
                        
                    </Row>
                    
                </Container>
           
            <div fluid className="service-bgcover">
                <Container pt="60px" pb="60px">
                    <Row>
                        <Col lg={6} className="mb-3">
                            <h5 className="mb-1"><span> <MdDone className="Tickmark" /> Migration</span></h5>
                            <ul className="text-white">
                                <li>Migrate existing applications and infrastructure to Cloud</li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h5 className="mb-1"><span> <MdDone className="Tickmark" /> Application Development</span></h5>
                            <ul>
                                <li> Develop cloud-native applications optimized to run at any scale.Updating legacy applications for Cloud.</li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h5 className="mb-1"><span> <MdDone className="Tickmark" /> Managed Services</span></h5>
                            <ul>
                                <li>Focus on your core business while we oversee the day-to-day management of your cloud infrastructure</li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h5 className="mb-1" ><span><MdDone className="Tickmark" /> Deployment</span></h5>
                            <ul>
                                <li>Deploy new applications and infrastructure in the Cloud</li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h5 className="mb-1"><span><MdDone className="Tickmark" /> Automation</span></h5>
                            <ul>
                                <li>Automate your entire cloud infrastructure using the latest tools from AWS, Ansible, Chef and Dockern</li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h5 className="mb-1"><span> <MdDone className="Tickmark" /> Cloud Strategy</span></h5>
                            <ul>
                                <li>Plan your move to the cloud or an expansion of your existing cloud deployment based on best practices we've developed across successful clients implementations</li>
                            </ul>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h5 className="mb-1"><span><MdDone className="Tickmark" /> Security & Governance</span></h5>
                            <ul>
                                <li>Secure your data and ensure that your cloud deployment meets strict compliance requirements.</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </SectionWrap>
    )
}

export default TabsSection
