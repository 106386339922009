import styled from 'styled-components';
import { device } from '../../../../theme'

export const SectionWrap = styled.div`
.row{align-items: center;}
padding-top:60px;
.rowspace{padding-bottom:60px;
    p{padding:60px 0;}
}
    li,h5{
        color:#ffffff
    }
ul{
    li{padding-left:40px;}
}
    @media ${device.medium}{
        padding-top: 70px;
        padding-bottom: 76px;
    }
    .Tickmark{font-weight: 400;
    color: #f36d21;
    font-size: 24px;
    line-height: 1;
    margin-right:10px
    }
    @media ${device.small}{
        padding-top: 50px;
        padding-bottom: 56px;
    }
    .sershape img{z-index: 2;height:500px;width:100%;position: relative;box-shadow:0 14px 18px rgba(0,0,0,000.08);border-radius: 8px;}
    .sershape-left::after{content: " ";width: 500px;height: 500px;background: #f36d21;margin: 10px;position: absolute;left: 5px;z-index: 1;top: -29px;}
    .sershape-right::after{content: " ";width: 500px;height: 500px;background: #f36d21;margin: 10px;position: absolute;right: -13px;z-index: 1;bottom: -29px;}
    .sershape img{z-index: 2;height:auto;width:100%;position: relative;}
    .sershape::after{content: " ";width: 482px;height: 217px;background: #f36d21;margin: 10px;position: absolute;right: 5px;z-index: 1;top: -25px;
    box-shadow: 0 1px 6px rgba(0,0,0,0.2), 1px 0px 3px rgba(0,0,0,0.1);}
    .headstyle{display:flex;align-items: center;margin-bottom:15px}
    .headtag{color:#717171; display:none}
    .service-bgcover .headtag{color:#ffffffa8}
    .service-bgcover{
        background:linear-gradient(0deg,rgba(52, 54, 110, 0.88),rgba(39,61,100,0.92)),url(../../../../../servicesection-web1.jpg);
        background-position: center center;
        background-size: cover;}
    .service-bgcolor{background: #e8e8e84a;padding-top:60px}
    .commandash{width:35px;height:3px;background:#f36d21;margin: 10px;display:none}
`;  